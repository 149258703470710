import { Injectable } from '@angular/core';
import { Observable, from as fromPromise } from 'rxjs';
import { environment } from '../../environments/environment';
import { TenantService } from '../core/tenant/tenant.service';
import { ApiService } from './api.service';
import { ScriptLoaderService } from './script-loader.service';
declare var cloudinary: any;

@Injectable()
export class ImageUploadV2Service {
  widget: any;

  constructor(
    private api: ApiService,
    scriptLoader: ScriptLoaderService,
    private tenants: TenantService
  ) {
    scriptLoader
      .load({
        name: 'cloudinay',
        src: '//widget.cloudinary.com/v2.0/global/all.js',
        loaded: false,
      })
      .subscribe(() => {
        // cloudinary.setCloudName(CLOUD_NAME);
        // this.widget = cloudinary.createUploadWidget(
        //   {
        //     cloudName: CLOUD_NAME,
        //     uploadPreset: UPLOAD_PRESET,
        //     uploadSignature: this.generateSignature,
        //   },
        //   (error, result) => {
        //     if (!error && result && result.event === 'success') {
        //       console.log(result);
        //       console.log('Done! Here is the image info: ', result.info);
        //     }
        //   }
        // );
      });
  }

  generateSignature = (callback, params_to_sign) => {
    this.api
      .post<any>(`signature`, { body: params_to_sign })
      .subscribe(
        (response) => {
          const data = response;
          callback(data.signature);
        },
        (err) => {
          console.error(err);
        }
      );
  }

  resultCallback(resolve, reject) {
    return (error, result) => {
      if (!error && result && result.event === 'success') {
        const { info } = result;
        const secureUrl = info.secure_url;
        resolve(secureUrl);
      } else if (error) {
        reject(error);
        console.log(error);
      }
    };
  }

  upload(
    tags: string | string[],
    croppingAspectRatio = 1.5,
    multiple = false
  ): Observable<any> {
    croppingAspectRatio = Number.parseFloat(croppingAspectRatio as any);
    const tagsArr = [];
    tagsArr.push(tags);
    const folder = this.tenants.tenantType.toLowerCase() + '-' + this.tenants.tenantName;

    return fromPromise(
      new Promise((resolve, reject) => {
        if (!cloudinary || !cloudinary.openUploadWidget) {
          reject(new Error('cloudinary not set'));
        }
        cloudinary.openUploadWidget(
          {
            cloudName: environment.cloudinaryName,
            api_key: environment.cloudinaryApiKey,
            uploadPreset: environment.cloudinaryPreset,
            uploadSignature: this.generateSignature,
            sources: ['local', 'url', 'image_search', 'instagram', 'facebook', 'dropbox'],
            clientAllowedFormats: ['png', 'gif', 'jpeg', 'svg'],
            defaultSource: 'local',
            maxFiles: 1,
            secure: true,
            multiple: false, // Do not work with cropping
            cropping: croppingAspectRatio ? true : false,
            croppingAspectRatio: croppingAspectRatio,
            croppingShowDimensions: croppingAspectRatio ? true : false,
            showSkipCropButton: false,
            googleApiKey: environment.googleApiKey,
            searchByRights: true,
            folder: `${folder}`,
            theme: 'minimal',
            styles: {
              palette: {
                tabIcon: '#000000',
                inactiveTabIcon: '#555a5f',
                link: '#00ACC2',
                inProgress: '#00BCD4',
                action: "#00ACC2",
              }
            },
          },
          this.resultCallback(resolve, reject),
        );
      })
    );
  }

  extractPublicId = (url) => {
    const arr = url.split("/");
    let key = '';
    arr.map(item => {
      if (item.indexOf('v') > -1 && Number(item.replace('v', '')) > 1234567890) {
        key = item;
      }
    })
    const publicId = (url.split(key + '/'))[1];
    return publicId.split('.')[0];
  };

  delete(imageUrl) {
    const public_id = this.extractPublicId(imageUrl);

    return fromPromise(
      new Promise((resolve, reject) => {
        this.api
          .get<any>(`delete-cls-asset`, { params: [{ paramName: 'public_id', paramValue: public_id }] })
          .subscribe(
            (response) => {
              resolve(response)
            },
            (err) => {
              console.error(err);
              reject(err)
            }
          );
      })
    )
  }
}
