import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ISocialAccess, ISocialPage, SocialMediaService } from '../social-media.service';
import { MatDialog } from '@angular/material/dialog';
import { FacebookPagesDialogComponent } from '../facebook-pages-dialog/facebook-pages-dialog.component';
import { TenantService } from '../../../core/tenant/tenant.service';

@Component({
  selector: 'app-social-buttons',
  templateUrl: './social-buttons.component.html',
  styleUrls: ['./social-buttons.component.scss']
})
export class SocialButtonsComponent implements OnInit {

  twitterSocialAccess: ISocialAccess;
  facebookSocialAccess: ISocialAccess;
  facebookCurrentPage: ISocialPage;
  instagramSocialAccess: ISocialAccess;
  instagramCurrentPage: ISocialPage;
  @Output() facebookCurrentPageChange = new EventEmitter<ISocialPage>();
  @Output() instagramCurrentPageChange = new EventEmitter<ISocialPage>();

  constructor(
    public socialService: SocialMediaService,
    public dialog: MatDialog,
    private tenant: TenantService,
  ) { }

  initSocialAccessStatus() {
    const sub = this.socialService.getSocialAccessStatus()
      .subscribe(sas => {
        this.facebookSocialAccess = sas.find(s => s.type === 'facebook');
        this.twitterSocialAccess = sas.find(s => s.type === 'twitter');
        this.instagramSocialAccess = sas.find(s => s.type === 'facebook' && s.pages && s.pages.some(page => page.IGUserID));

        this.facebookCurrentPage = (this.facebookCurrentPage)
          ? this.facebookPages.find((p) => this.facebookCurrentPage.id === p.id)
          : undefined;
        if (!this.facebookCurrentPage && this.facebookSocialAccess && this.facebookSocialAccess.pages) {
          const fbPage = this.facebookPages.find(page => !!page.name);
          this.facebookSelectPage(fbPage);
        }

        // IG setup
        this.instagramCurrentPage = (this.instagramCurrentPage)
          ? this.instagramPages.find((p) => this.instagramCurrentPage.id === p.id)
          : undefined;
        if (!this.instagramCurrentPage && this.instagramSocialAccess && this.instagramSocialAccess.pages) {
          const igPage = this.instagramPages.find(page => !!page.name);
          this.instagramSelectPage(igPage);
        }
        sub.unsubscribe();
      });
  }
  twitterSignIn() {
    this.socialService.twitterSignIn().subscribe();
  }

  facebookSignIn() {
    this.socialService.facebookSignIn().subscribe((fbAuth) => {
      const dialogRef = this.dialog.open(FacebookPagesDialogComponent, {
        width: '600px',
        data: fbAuth
      });
      dialogRef.afterClosed().subscribe((res: ISocialAccess) => {
        if (!res) { return; }
        this.socialService.facebookUpdateSelectedPages(res)
          .subscribe((fbSa) => {
            this.facebookSocialAccess = fbSa;
            const fbPage = this.facebookPages.find(page => !!page.name);
            if (fbPage) { this.facebookSelectPage(fbPage); }

            // set instagram
            this.instagramSocialAccess = fbSa;
            const igPage = this.instagramPages.find(page => !!page.name);
            if (igPage) { this.instagramSelectPage(igPage); }
          });
      });
    });
  }

  get facebookPages() {
    if (!this.facebookSocialAccess || !this.facebookSocialAccess.pages) { return []; }
    const showPages = this.facebookSocialAccess.pages.filter((page) => page.show);
    return showPages;
  }

  get instagramPages() {
    if (!this.instagramSocialAccess || !this.instagramSocialAccess.pages) { return []; }
    const showPages = this.instagramSocialAccess.pages.filter((page) => page.show && page.IGUserID);
    return showPages;
  }

  facebookSignOut(saFB: ISocialAccess) {
    this.socialService.facebookSignOut(saFB.username).subscribe(() => {
      this.initSocialAccessStatus();
    });
  }

  twitterSignOut(saTw: ISocialAccess) {
    this.socialService.twitterSignOut(saTw.username).subscribe(() => {
      this.initSocialAccessStatus();
    });
  }

  facebookSelectPage(page: ISocialPage) {
    this.socialService.changeSelectedFbPage(page);
    this.facebookCurrentPageChange.emit(this.facebookCurrentPage);
  }

  instagramSelectPage(page: ISocialPage) {
    this.socialService.changeSelectedIGPage(page);
    this.instagramCurrentPageChange.emit(this.instagramCurrentPage);
  }

  isDemo() {
    if (this.tenant.tenantName) {
      const tenantName = this.tenant.tenantName;
      return tenantName.indexOf('demo') > -1;
    }
  }

  ngOnInit() {
    this.socialService.selectedFbPage$
      .subscribe((selectedFbPage) => {
        if (selectedFbPage) { this.facebookCurrentPage = selectedFbPage; }
      });
    this.socialService.selectedIGPage$
      .subscribe((selectedIGPage) => {
        if (selectedIGPage) { this.instagramCurrentPage = selectedIGPage; }
      });
    this.initSocialAccessStatus();
  }

}
