import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError, map, flatMap, skipWhile } from 'rxjs/operators';
import { ScriptLoaderService } from '../../providers/script-loader.service';
import { BehaviorSubject ,  from as fromPromise } from 'rxjs';
import { ApiService } from '../../providers/api.service';
declare var FB: any;

const twitterApiUrl = 'https://api.twitter.com/oauth/authenticate';
interface IOauth {
  oauthToken: string;
  oauthTokenSecret: string;
  oauthVerifier?: string;
  type?: string;
}
export interface ISocial {
  twitter: boolean;
  facebook: boolean | string;
  instagram: boolean | string;
 }
export interface ISocialAccess {
  type: 'twitter' | 'facebook';
  hasAccess: boolean;
  username: string;
  pages?: ISocialPage[];
}
export interface ISocialPage {
  id: string;
  name: string;
  IGUserID?: string;
  show: boolean;
}
@Injectable()
export class SocialMediaService {
  private hasAccessFacebookSource = new BehaviorSubject<ISocialAccess>(undefined);
  hasAccessFacebook$ = this.hasAccessFacebookSource.asObservable();

  private hasAccessTwitterSource = new BehaviorSubject<ISocialAccess>(undefined);
  hasAccessTwitter$ = this.hasAccessTwitterSource.asObservable();

  private hasAccessInstagramSource = new BehaviorSubject<ISocialAccess>(undefined);
  hasAccessInstagram$ = this.hasAccessInstagramSource.asObservable();

  private selectedFbPageSource = new BehaviorSubject<ISocialPage>(undefined);
  selectedFbPage$ = this.selectedFbPageSource.asObservable().pipe(skipWhile(selected => !selected));

  private selectedIGPageSource = new BehaviorSubject<ISocialPage>(undefined);
  selectedIGPage$ = this.selectedIGPageSource.asObservable().pipe(skipWhile(selected => !selected));

  constructor(
    private api: ApiService,
    private scriptLoader: ScriptLoaderService,
  ) {
    this.scriptLoader.load({
      name: 'facebook',
      src: '//connect.facebook.net/en_US/sdk.js',
      loaded: false,
    }).subscribe(() => {
        FB.init({
          appId: environment.facebookAppId,
          cookie: false,  // enable cookies to allow the server to access
          // the session
          xfbml: true,  // parse social plugins on this page
          version: 'v20.0' // use latest graph api version
        });
      });
  }
  private _facebookToken() {
    return fromPromise(
      new Promise<{ accessToken: string, userId: string }>((resolve, reject) => {
        console.log('_facebookToken');
        FB.login((response) => {
          if (response.status === 'connected' && response.authResponse) {
            resolve({
              accessToken: response.authResponse.accessToken,
              userId: response.authResponse.userID
            });
          } else {
            reject('Error to get fb token');
          }
        }, { scope: 'pages_manage_metadata, pages_read_engagement, pages_read_user_content, pages_manage_posts, pages_manage_engagement, pages_show_list' });
      })
    );
  }
  twitterSignIn() {
    return this.api.get<IOauth>(`oauth/twitter`)
      .pipe(
        map(response => {
          const oauth: IOauth = response;
          localStorage.setItem('tw_oauth_token', oauth.oauthToken);
          localStorage.setItem('tw_oauth_token_secret', oauth.oauthTokenSecret);
          const query = `?oauth_token=${oauth.oauthToken}&oauth_token_secret=${oauth.oauthTokenSecret}&oauth_callback_confirmed=true`;
          window.location.href = `${twitterApiUrl}${query}`;
        }, catchError((err) => {
          throw Error(err);
          // return of();
        }))
      );
  }

  facebookSignIn() {
    return this._facebookToken()
      .pipe(
        flatMap(fbAuth => {
          return this.api.post<ISocialAccess>(`auth/facebook`, {body: fbAuth, withTenant: true})
            .pipe(
              map(response => {
                const data: ISocialAccess = response;
                return data;
              }, catchError((err) => {
                throw Error(err);
                // return of();
              }))
            );
        })
      );
  }

  facebookUpdateSelectedPages(fbSa: ISocialAccess) {
    return this.api.put<ISocialAccess>(`social-access/facebook`, {body: fbSa, withTenant: true})
      .pipe(
        map(response => {
          const data: ISocialAccess = response;
          this.hasAccessFacebookSource.next(data);
          return data;
        }, catchError((err, caught) => {
          throw Error(err);
          // return of();
        }))
      );
  }

  instagramUpdateSelectedPages(fbSa: ISocialAccess) {
    return this.api.put<ISocialAccess>(`social-access/facebook`, {body: fbSa, withTenant: true})
      .pipe(
        map(response => {
          const data: ISocialAccess = response;
          this.hasAccessFacebookSource.next(data);
          return data;
        }, catchError((err, caught) => {
          throw Error(err);
          // return of();
        }))
      );
  }

  twitterSignOut(username) {
    return this.api.delete<ISocialAccess>(`social-access/twitter`, {
      params: [{paramName: 'username', paramValue: username}],
      withTenant: true})
      .pipe(
        map(response => {
          const data: ISocialAccess = response;
          return data;
        }, catchError((err) => {
          throw Error(err);
          // return of();
        }))
      );
  }

  facebookSignOut(username) {
    return this.api.delete<ISocialAccess>(`social-access/facebook`, {
      withTenant: true,
      params: [{ paramName: 'username', paramValue: username}] })
      .pipe(
        map(response => {
          const data: ISocialAccess = response;
          return data;
        }, catchError((err) => {
          throw Error(err);
          // return of();
        }))
      );
  }

  twitterSetSocialAccess(oauthVerifier: string) {
    const oauth: IOauth = {
      oauthToken: localStorage.getItem('tw_oauth_token'),
      oauthTokenSecret: localStorage.getItem('tw_oauth_token_secret'),
      oauthVerifier: oauthVerifier,
      type: 'twitter',
    };
    localStorage.removeItem('tw_oauth_token');
    localStorage.removeItem('tw_oauth_token_secret');
    return this.api.post<ISocialAccess>(`social-access`, {body: oauth, withTenant: true})
      .pipe(
        map(response => {
          const data: ISocialAccess = response;
          return data;
        }, catchError((err) => {
          throw Error(err);
          // return of();
        }))
      );
  }

  getSocialAccessStatus() {
    return this.api.get<ISocialAccess[]>(`social-access`, { withTenant: true })
      .pipe(
        map(response => {
          const data: ISocialAccess[] = response;
          this.hasAccessFacebookSource.next(data.find(s => s.type === 'facebook'));
          this.hasAccessTwitterSource.next(data.find(s => s.type === 'twitter'));
          const instagramAccess = data.find(s => s.type === 'facebook' && s.pages && s.pages.some(page => page.IGUserID));
          this.hasAccessInstagramSource.next(instagramAccess);
          return data;
        }, catchError((err) => {
          throw Error(err);
          // return of([]);
        }))
      );
  }

  changeSelectedFbPage(fbPage: ISocialPage) {
    this.selectedFbPageSource.next(fbPage);
  }

  changeSelectedIGPage(IGPage: ISocialPage) {
    this.selectedIGPageSource.next(IGPage);
  }
}
